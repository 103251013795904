/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Box, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react';
import { Slide } from 'react-awesome-reveal';

const Proposal = () => (
  <Box as='section' className='section our-proposal'>
    <Box className='shapes-container' zIndex={-1}>
      <Slide direction='right' damping={0.5} cascade triggerOnce>
        <div className='shape shape-circle pattern-dots' />
        <div className='shape shape-circle shape-circle-fill' />
      </Slide>
      <div className='pattern pattern-dots' />
    </Box>

    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
      gap={20}
      maxW={{ base: 'xl', md: '7xl' }}
      mx='auto'
      mt={{ base: 20, md: '150px' }}
      py={{ base: 6, md: 20 }}
      px={{ base: '6', md: '8' }}
      flexDirection='column-reverse'
    >
      <GridItem className='bring-to-front' colSpan={1}>
        <Heading as='h3' size={{ base: 'md', md: 'lg' }} mb={6}>
          Take the mystery and hassle out of vitamins and supplements
        </Heading>
        <Text fontSize='xl' color='gray.500' mb={6}>
          Young or old, fit or unfit, vegetarian or carnivore... we create vitamin and supplement schedules to meet
          individual requirements.
        </Text>
        <Text fontSize='xl' color='gray.500'>
          At Vitamin Club, we have married technology, research and logistics to deliver personalised vitamin profiles
          to you in a simple, cost-effective and convenient way.
        </Text>
      </GridItem>

      <GridItem className='bring-to-front' colSpan={1}>
        <Image src='/images/undraw-02@2x.png' width='100%' my={20} loading='lazy' alt='Healthy living' />
      </GridItem>
    </Grid>
  </Box>
);

export default Proposal;
